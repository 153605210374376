//headerメガメニューhover設定
$(function () {
  $('.l-nav_menu_name').hover(function () {
    $(this).find('.l-nav_megaMenu').stop().slideDown(150);
  }, function () {
    $(this).find('.l-nav_megaMenu').stop().slideUp(400);
  });
});

$(function () {
  $(".l-nav_control").on("click", function () {
    $(this).find(".l-nav_panel").slideToggle();
    $(this).toggleClass("open");
    $(".l-nav_control").not($(this)).find(".l-nav_panel").slideUp();
    $(".l-nav_control").not($(this)).removeClass("open");
  });
});

//headerブロックリンク設定
$(function () {
  $(".block_link").on("click", function () {
    location.href = $(this).attr("data-url");
  });
});

// topページタブメニュー動作
$(function () {
  $(".c-tab_button").on("click", function () {
    let index = $(".c-tab_button").index(this);

    $(".c-tab_button").removeClass("is-btn-active");
    $(this).addClass("is-btn-active");
    $(".l-tab_contents").removeClass("is-contents-active");
    $(".l-tab_contents").eq(index).addClass("is-contents-active");
  });
});

$(function () {
  $('#sp_select_tab').change(function () {
    //選択したoptionのvalueを取得
    const val = $(this).val();
    //先頭に#を付けてvalueの値をidに変換
    const selectPhotoId = '#' + val;
    $('.l-tab_contents').hide();
    $(selectPhotoId).show();
  });
});

//top swiper設定
var mySwiper = new Swiper(".swiper-container3", {
  effect: "fade",
  speed: 1000,
  loop: true,
  slidesPerView: 1,
  autoplay: {
    delay: 3000,
    stopOnLastSlide: false,
    disableOnInteraction: false,
    reverseDirection: false
  },
  pagination: {
    el: ".swiper-pagination",
    type: "bullets",
  },
});
//top スマホ版swiper設定
var mySwiper = new Swiper(".swiper-container4", {
  effect: "fade",
  speed: 1000,
  loop: true,
  slidesPerView: 1,
  autoplay: {
    delay: 4000,
    stopOnLastSlide: false,
    disableOnInteraction: false,
    reverseDirection: false,
  },
  pagination: {
    el: ".swiper-pagination",
    type: "bullets",
    clickable: true,
  },
});
//top 同窓生紹介  swiper設定
var mySwiper = new Swiper(".swiper-container5", {
  loop: true,
  centeredSlides: true,
  slidesPerView: 5,
  speed: 1000,
  autoplay: {
    delay: 5000,
    stopOnLastSlide: false,
    disableOnInteraction: false,
    reverseDirection: false,
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});

//top 同窓生紹介スマホ版  swiper設定
var mySwiper = new Swiper(".swiper-container6", {
  loop: true,
  centeredSlides: true,
  slidesPerView: 1.5,
  speed: 1000,
  autoplay: {
    delay: 4000,
    stopOnLastSlide: false,
    disableOnInteraction: false,
    reverseDirection: false,
  },
  breakpoints: {
    // スライドの表示枚数：600px以上の場合
    640: {
      slidesPerView: 2.5,
    },
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});

//top footer swiper設定
var mySwiper = new Swiper(".swiper-container7", {
  loop: true,
  slidesPerView: 2,
  speed: 15000,
  autoplay: {
    delay: 0,
    stopOnLastSlide: false,
    disableOnInteraction: false,
    reverseDirection: false,
  },
  breakpoints: {
    // スライドの表示枚数：640px以上の場合
    640: {
      slidesPerView: 2,
    },
    // スライドの表示枚数：768px以上の場合
    768: {
      slidesPerView: 3,
    },
    // スライドの表示枚数：1025px以上の場合
    1025: {
      slidesPerView: 4,
    },
  },
});

// 左メニューcurrent表示判定
// 標準
$(function () {
  /*$('.l-page_common_leftMenu_list .current').removeClass('current');*/
  $('.l-page_common_leftMenu_list a').filter(function () { return $(this).prop('href') == location.href; }).addClass('current');
});

//下階層が深いとき、上階層にもcurrent_under判定をつける
$(function () {
  $('.l-page_common_leftMenu_list a').each(function () {
    const $href = $(this).attr('href');
    if (location.href.match($href)) {
      $(this).parent().addClass('current_under');
    } else {
      $(this).parent().removeClass('current_under');
    }
  });
});
